import { createSlice } from '@reduxjs/toolkit';
import { APP_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    visibleModals: [],
};
var modalServiceState = createSlice({
    name: APP_SERVICES_REDUCER_NAMESPACES.modalService,
    initialState: initialState,
    reducers: {
        addModal: function (state, action) {
            state.visibleModals = state.visibleModals.concat(action.payload);
        },
        removeModal: function (state, action) {
            state.visibleModals = state.visibleModals.filter(function (el) { return el.id !== action.payload; });
        },
        hideLastModal: function (state) {
            state.visibleModals = state.visibleModals.splice(0, -1);
        },
    },
});
export var actions = modalServiceState.actions;
export default modalServiceState.reducer;
