import * as Yup from 'yup';
import { getMaxFilesSizeSchema } from 'common/schemas';
export var getCreateSupportRequestFormSchema = function (t) {
    return Yup.object().shape({
        typeId: Yup.string().required(t('common.createSupportRequestForm.errors.typeIdReq')).nullable(),
        creatorId: Yup.string().required(t('common.createSupportRequestForm.errors.creatorIdReq')).nullable(),
        text: Yup.string()
            .max(1000, t('common.errorTexts.maxLength', { count: 1000 }))
            .required(t('common.createSupportRequestForm.errors.textReq')),
        formFileCollection: getMaxFilesSizeSchema(),
    });
};
