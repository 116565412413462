import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { customizationsServiceStylesMapSelector } from 'common/services/customizations-service/selectors';
import { checkEqual } from 'common/helpers/checkEqual';
export var ThemeObserver = function () {
    var customizationStylesMap = useSelector(customizationsServiceStylesMapSelector, checkEqual);
    useEffect(function () {
        if (!customizationStylesMap)
            return;
        var rootStyles = document.documentElement.style;
        Object.entries(customizationStylesMap).forEach(function (_a) {
            var key = _a[0], value = _a[1].value;
            if (value)
                rootStyles.setProperty("--".concat(key), value);
        });
    }, [customizationStylesMap]);
    return null;
};
