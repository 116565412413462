var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import cs from 'classnames';
import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import { Icon } from 'ui-kit/icon';
import { ArrowLeftSimple, ArrowRightSimple } from 'common/icons';
import { TabsObservableProvider } from './modules/tabs-observable/tabs-observable-provider';
import { useTabsSubscribers } from './modules/tabs-observable/use-tabs-subscriber';
import styles from './tabs.module.scss';
var TabsList = function (_a) {
    var tabs = _a.tabs, withTabsCarousel = _a.withTabsCarousel;
    var _b = useTabsSubscribers({
        observeActiveTab: true,
        observeAllDisabledTabs: true,
    }), activeTabIndex = _b.activeTabIndex, checkDisabledTabStatus = _b.checkDisabledTabStatus, setActiveTab = _b.setActiveTab;
    return (_jsx(_Fragment, { children: tabs.map(function (label, index) {
            var _a;
            return (_jsx("li", __assign({ className: cs(styles.tab, (_a = {},
                    _a[styles.active] = index === activeTabIndex,
                    _a.active = index === activeTabIndex,
                    _a[styles.disabled] = checkDisabledTabStatus(index),
                    _a[styles.noWrap] = withTabsCarousel,
                    _a)), "data-testid": "tab-".concat(index), onClick: function () { return setActiveTab(index); } }, { children: label }), index));
        }) }));
};
var TabsRoot = function (_a) {
    var tabs = _a.tabs, withTabsCarousel = _a.withTabsCarousel, className = _a.className, style = _a.style, children = _a.children;
    var setActiveTab = useTabsSubscribers({}).setActiveTab;
    var handleChoiceTab = useCallback(function (index) { return function () {
        setActiveTab(index);
    }; }, [setActiveTab]);
    return (_jsxs(_Fragment, { children: [_jsx("ul", __assign({ className: cs(styles.tabsController, className), style: style }, { children: !withTabsCarousel ? (_jsx(TabsList, { tabs: tabs })) : (_jsx(ScrollingCarousel, __assign({ className: styles.carousel, leftIcon: _jsx("div", __assign({ className: styles.arrowLeftWrapper }, { children: _jsx(Icon, { width: 1, height: 1, viewBox: "0 0 24 24", className: styles.arrowLeft, component: ArrowLeftSimple }) })), rightIcon: _jsx("div", __assign({ className: styles.arrowRightWrapper }, { children: _jsx(Icon, { width: 1, height: 1, viewBox: "0 0 24 24", className: styles.arrowRight, component: ArrowRightSimple }) })) }, { children: (_jsx(TabsList, { withTabsCarousel: true, tabs: tabs })) }))) })), typeof children === 'function' ? children({ setActiveTab: handleChoiceTab }) : children] }));
};
export var Tabs = function (_a) {
    var initialActiveTab = _a.initialActiveTab, initialDisabledTabs = _a.initialDisabledTabs, disabled = _a.disabled, additionalTabClickHandler = _a.additionalTabClickHandler, rest = __rest(_a, ["initialActiveTab", "initialDisabledTabs", "disabled", "additionalTabClickHandler"]);
    return (_jsx(TabsObservableProvider, __assign({ initialActiveTab: initialActiveTab, initialDisabledTabs: initialDisabledTabs, disabled: disabled, additionalTabClickHandler: additionalTabClickHandler }, { children: _jsx(TabsRoot, __assign({}, rest)) })));
};
