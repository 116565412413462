var _a;
import { combineReducers } from 'redux';
import { appServicesRootReducer } from 'common/services/app-services-root-reducer';
import { APP_SERVICES_REDUCER_NAMESPACES } from 'common/services/constants';
import { sharedServicesRootReducer } from 'pages/services/root-reducer';
import { SHARED_SERVICES_REDUCER_NAMESPACES } from 'pages/services/constants';
import { homeRootServicesReducer } from 'pages/user-pages/home/services/root-reducer';
import { HOME_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/home/services/constants';
import pinnedPostsListServiceReducer from 'pages/user-pages/modules/pinned-posts-list/service/reducer';
import { PINNED_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/modules/pinned-posts-list/service/constants';
import { placeMarketServicesRootReducer } from 'pages/user-pages/place-market/services/root-reducer';
import { PLACE_MARKET_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/place-market/services/constants';
import { userServicesRootReducer } from 'pages/user-pages/profile/services/root-reducer';
import { USER_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/profile/services/constants';
import { staffServicesRootReducer } from 'pages/manager-pages/employees/services/root-reducer';
import { STAFF_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/employees/services/constants';
import { applicationServicesRootReducer } from 'pages/manager-pages/applications/services/root-reducer';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/applications/services/constants';
import { ordersServicesRootReducer } from 'pages/manager-pages/orders/services/root-reducer';
import { ORDERS_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/orders/services/constants';
import { activitiesPageServicesRootReducer } from 'pages/manager-pages/activities/services/root-reducer';
import { ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/activities/services/constants';
import { productsServicesRootReducer } from 'pages/manager-pages/products/services/root-reducer';
import { PRODUCTS_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/products/services/constants';
import { statisticsRootReducer } from 'pages/manager-pages/statistics/services/root-reducer';
import { STATISTICS_SERVICES_REDUCER_NAMESPACES as NEW_STATISTICS_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/statistics/services/constants';
import sidebarServiceReducer from 'common/layouts/main-layout/service/sidebar-service/reducer';
import { SIDEBAR_SERVICES_REDUCER_NAMESPACES } from 'common/layouts/main-layout/service/sidebar-service/constants';
import notificationDrawerServiceReducer from 'common/layouts/main-layout/service/notification-drawer-service/reducer';
import { NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES } from 'common/layouts/main-layout/service/notification-drawer-service/constants';
import { usersServicesRootReducer } from 'pages/admin-pages/users/services/root-reducer';
import { USERS_SERVICES_REDUCER_NAMESPACES } from 'pages/admin-pages/users/services/constants';
import { organizationStructurePageServicesRootReducer } from 'pages/user-pages/organization-structure/services/root-reducer';
import { ORGANIZATION_STRUCTURE_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/organization-structure/services/constants';
import { userActivitiesRootReducer } from 'pages/user-pages/income-teal/services/root-reducer';
import { USER_ACTIVITIES_REDUCER_NAMESPACES } from 'pages/user-pages/income-teal/services/constants';
import reciveTealFormServiceReducer from 'pages/user-pages/modules/application-recive-teal-form/service/reducer';
import { RECIVE_TEAL_FORM_SERVICE_REDUCER_NAMESPACES } from 'pages/user-pages/modules/application-recive-teal-form/service/constants';
import { surveysRootServicesReducer } from 'pages/manager-pages/surveys/services/root-reducer';
import { SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/surveys/services/constants';
import { takeSurveyFormServiceRootReducer } from 'common/components/take-survey-form/services/root-reducer';
import { TAKE_SURVEY_SERVICES_REDUCER_NAMESPACES } from 'common/components/take-survey-form/services/constants';
import { supportRequestsServicesReducer } from 'pages/manager-pages/support-requests/services/root-reducer';
import { SUPPORT_REQUESTS_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/support-requests/services/constants';
import { addressBookRootServicesReducer } from 'pages/user-pages/address-book/services/root-reducer';
import { ADDRESS_BOOK_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/address-book/services/constants';
import { developmentBlockRootServicesReducer } from 'pages/user-pages/development-block/services/root-reducer';
import { DEVELOPMENT_BLOCK_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/development-block/services/constants';
import { oneToOneFormRootServicesReducer } from 'pages/user-pages/development-block/modules/one-to-one-form/services/root-reducer';
import { ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/development-block/modules/one-to-one-form/services/constants';
import { detailPostRootServicesReducer } from 'pages/user-pages/detail-post-pages/detail-post/services/root-reducer';
import { DETAIL_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/detail-post-pages/detail-post/services/constants';
import { detailActivityPostRootServicesReducer } from 'pages/user-pages/detail-post-pages/detail-activity-announcement-page/services/root-reducer';
import { DETAIL_ACTIVITY_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/detail-post-pages/detail-activity-announcement-page/services/constants';
import { detailSurveyPostRootServicesReducer } from 'pages/user-pages/detail-post-pages/detail-survey-post-page/services/root-reducer';
import { DETAIL_SURVEY_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/detail-post-pages/detail-survey-post-page/services/constants';
import { detailLeaderPostRootServicesReducer } from 'pages/user-pages/detail-post-pages/detail-leader-page/services/root-reducer';
import { DETAIL_LEADER_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/detail-post-pages/detail-leader-page/services/constants';
import { detailGratitudePostRootServicesReducer } from 'pages/user-pages/detail-post-pages/detail-gratitude-post/services/root-reducer';
import { DETAIL_GRATITUDE_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/detail-post-pages/detail-gratitude-post/services/constants';
import editPostServiceReducer from 'pages/manager-pages/posts/edit-post-page/service/reducer';
import { EDIT_POST_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/posts/edit-post-page/service/constants';
import createPostServiceReducer from 'pages/manager-pages/posts/create-post/service/reducer';
import { CREATE_POST_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/posts/create-post/service/constants';
import { applicationsServicesRootReducer } from 'pages/supervisor-pages/applications/services/root-reducer';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES as SUPERVISOR_APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from 'pages/supervisor-pages/applications/services/constants';
import { ratingsRootServicesReducer } from 'pages/user-pages/ratings/services/root-reducer';
import { RATINGS_ROOT_SERVICE_REDUCER_NAMESPACES } from 'pages/user-pages/ratings/services/constants';
import postsCommentsServiceReducer from 'pages/user-pages/modules/post-comments/service/reducer';
import { POSTS_COMMENTS_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/modules/post-comments/service/constants';
import chatRootReducer from 'pages/user-pages/chat/service/reducer';
import { CHAT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/chat/service/constants';
import { userPagesServicesRootReducer } from 'pages/user-pages/services/root-reducer';
import { USER_PAGES_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/services/constants';
import editOneToOneFormServiceReducer from 'pages/user-pages/development-block/edit-one-to-one-page/service/reducer';
import { EDIT_ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/development-block/edit-one-to-one-page/service/constants';
import { settingsServicesRootReducer } from 'pages/manager-pages/settings/services/root-reducer';
import { SETTINGS_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/settings/services/constants';
import thanksJoinServiceReducer from 'pages/user-pages/modules/thanks-join/service/reducer';
import { THANKS_JOIN_SERVICE_REDUCER_NAMESPACES } from 'pages/user-pages/modules/thanks-join/service/constants';
import awardFormServiceReducer from 'pages/manager-pages/awards/modules/award-form/service/reducer';
import { AWARD_FORM_SERVICE_REDUCER_NAMESPACES } from 'pages/manager-pages/awards/modules/award-form/service/constants';
import editAwardServiceReducer from 'pages/manager-pages/awards/edit-award-page/service/reducer';
import { EDIT_AWARD_FORM_SERVICE_REDUCER_NAMESPACES } from 'pages/manager-pages/awards/edit-award-page/service/constants';
import { awardsServicesRootReducer } from 'pages/user-pages/awards/services/root-reducer';
import { AWARDS_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/awards/services/constants';
import { detailBirthdayPostRootServicesReducer } from 'pages/user-pages/detail-post-pages/detail-birthday-page/services/root-reducer';
import { DETAIL_BIRTHDAY_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/detail-post-pages/detail-birthday-page/services/constants';
import { surpriseBoxesServicesRootReducer } from 'pages/manager-pages/surprise-boxes/services/root-reducer';
import { SURPRISE_BOXES_ROOT_REDUCER_NAMESPACES } from 'pages/manager-pages/surprise-boxes/services/constants';
import { userSurpriseBoxesServicesRootReducer } from 'pages/user-pages/surprise-boxes/services/root-reducer';
import { USER_SURPRISE_BOXES_ROOT_REDUCER_NAMESPACES } from 'pages/user-pages/surprise-boxes/services/constants';
import { surveysCommonServicesRootReducer } from 'pages/manager-pages/modules/surveys-common/services/root-reducer';
import { SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/modules/surveys-common/services/constants';
export default combineReducers((_a = {},
    _a[APP_SERVICES_REDUCER_NAMESPACES.common] = appServicesRootReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.shared] = sharedServicesRootReducer,
    _a[SIDEBAR_SERVICES_REDUCER_NAMESPACES.sidebar] = sidebarServiceReducer,
    _a[SURPRISE_BOXES_ROOT_REDUCER_NAMESPACES.surpriseBoxesRoot] = surpriseBoxesServicesRootReducer,
    _a[USER_SURPRISE_BOXES_ROOT_REDUCER_NAMESPACES.surpriseBoxesRoot] = userSurpriseBoxesServicesRootReducer,
    _a[NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer] = notificationDrawerServiceReducer,
    _a[USER_SERVICES_REDUCER_NAMESPACES.user] = userServicesRootReducer,
    _a[HOME_ROOT_SERVICES_REDUCER_NAMESPACES.homeRoot] = homeRootServicesReducer,
    _a[ORGANIZATION_STRUCTURE_SERVICES_REDUCER_NAMESPACES.organizationStructureRoot] = organizationStructurePageServicesRootReducer,
    _a[PINNED_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES.pinnedPostsList] = pinnedPostsListServiceReducer,
    _a[ADDRESS_BOOK_ROOT_SERVICES_REDUCER_NAMESPACES.addressBookRoot] = addressBookRootServicesReducer,
    _a[DEVELOPMENT_BLOCK_ROOT_SERVICES_REDUCER_NAMESPACES.developmentBlockRoot] = developmentBlockRootServicesReducer,
    _a[ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES.oneToOneFormRoot] = oneToOneFormRootServicesReducer,
    _a[TAKE_SURVEY_SERVICES_REDUCER_NAMESPACES.takeSurvey] = takeSurveyFormServiceRootReducer,
    _a[PLACE_MARKET_SERVICES_REDUCER_NAMESPACES.placeMarket] = placeMarketServicesRootReducer,
    _a[USER_ACTIVITIES_REDUCER_NAMESPACES.userActivities] = userActivitiesRootReducer,
    _a[RECIVE_TEAL_FORM_SERVICE_REDUCER_NAMESPACES.reciveTealForm] = reciveTealFormServiceReducer,
    _a[STAFF_SERVICES_REDUCER_NAMESPACES.staff] = staffServicesRootReducer,
    _a[APPLICATIONS_SERVICES_REDUCER_NAMESPACES.applications] = applicationServicesRootReducer,
    _a[ORDERS_SERVICES_REDUCER_NAMESPACES.orders] = ordersServicesRootReducer,
    _a[ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES.activitesPageRoot] = activitiesPageServicesRootReducer,
    _a[PRODUCTS_SERVICES_REDUCER_NAMESPACES.products] = productsServicesRootReducer,
    _a[NEW_STATISTICS_SERVICES_REDUCER_NAMESPACES.statisticsRoot] = statisticsRootReducer,
    _a[USERS_SERVICES_REDUCER_NAMESPACES.users] = usersServicesRootReducer,
    _a[SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES.surveysRoot] = surveysRootServicesReducer,
    _a[SUPPORT_REQUESTS_ROOT_SERVICES_REDUCER_NAMESPACES.supportRequestsRoot] = supportRequestsServicesReducer,
    _a[DETAIL_POST_ROOT_SERVICES_REDUCER_NAMESPACES.detailPostRoot] = detailPostRootServicesReducer,
    _a[DETAIL_ACTIVITY_POST_ROOT_SERVICES_REDUCER_NAMESPACES.detailPostRoot] = detailActivityPostRootServicesReducer,
    _a[DETAIL_SURVEY_POST_ROOT_SERVICES_REDUCER_NAMESPACES.detailSurveyPostRoot] = detailSurveyPostRootServicesReducer,
    _a[DETAIL_GRATITUDE_POST_ROOT_SERVICES_REDUCER_NAMESPACES.detailGratitudePostRoot] = detailGratitudePostRootServicesReducer,
    _a[EDIT_POST_SERVICES_REDUCER_NAMESPACES.editPostForm] = editPostServiceReducer,
    _a[SUPERVISOR_APPLICATIONS_SERVICES_REDUCER_NAMESPACES.supervisorApplicationsRoot] = applicationsServicesRootReducer,
    _a[RATINGS_ROOT_SERVICE_REDUCER_NAMESPACES.ratingsRoot] = ratingsRootServicesReducer,
    _a[POSTS_COMMENTS_SERVICES_REDUCER_NAMESPACES.postsComments] = postsCommentsServiceReducer,
    _a[CHAT_SERVICES_REDUCER_NAMESPACES.chatList] = chatRootReducer,
    _a[USER_PAGES_SERVICES_REDUCER_NAMESPACES.root] = userPagesServicesRootReducer,
    _a[EDIT_ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES.editOneToOneForm] = editOneToOneFormServiceReducer,
    _a[SETTINGS_SERVICES_REDUCER_NAMESPACES.settingsRoot] = settingsServicesRootReducer,
    _a[THANKS_JOIN_SERVICE_REDUCER_NAMESPACES.thanksJoin] = thanksJoinServiceReducer,
    _a[AWARD_FORM_SERVICE_REDUCER_NAMESPACES.awardForm] = awardFormServiceReducer,
    _a[EDIT_AWARD_FORM_SERVICE_REDUCER_NAMESPACES.editAwardForm] = editAwardServiceReducer,
    _a[AWARDS_SERVICES_REDUCER_NAMESPACES.awardsServicesRoot] = awardsServicesRootReducer,
    _a[DETAIL_LEADER_POST_ROOT_SERVICES_REDUCER_NAMESPACES.detailLeaderPostRoot] = detailLeaderPostRootServicesReducer,
    _a[DETAIL_BIRTHDAY_POST_ROOT_SERVICES_REDUCER_NAMESPACES.detailBirthdayPostRoot] = detailBirthdayPostRootServicesReducer,
    _a[SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.surveysCommonRoot] = surveysCommonServicesRootReducer,
    _a[CREATE_POST_SERVICES_REDUCER_NAMESPACES.createPostForm] = createPostServiceReducer,
    _a));
