var prepareValueToRem = function (value) {
    var valueNumber = parseInt(value);
    if (Number.isNaN(valueNumber))
        valueNumber = 0;
    return "".concat(valueNumber && valueNumber / 16, "rem");
};
var getShadowColor = function (value) {
    return value &&
        "".concat(prepareValueToRem(value.x), " ").concat(prepareValueToRem(value.y), " ").concat(prepareValueToRem(value.blur), " ").concat(prepareValueToRem(value.spread), " ").concat(value.color);
};
export var getCustomizationColorStylesMap = function (data) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59;
    return (_a = {},
        _a['background-globe'] = { value: (_c = (_b = data === null || data === void 0 ? void 0 : data.background) === null || _b === void 0 ? void 0 : _b.globe) === null || _c === void 0 ? void 0 : _c.value, defaultValue: 'rgb(252, 252, 255)' },
        _a['background-island'] = { value: (_e = (_d = data === null || data === void 0 ? void 0 : data.background) === null || _d === void 0 ? void 0 : _d.island) === null || _e === void 0 ? void 0 : _e.value, defaultValue: 'rgb(255, 255, 255)' },
        _a['background-island-inner'] = { value: (_g = (_f = data === null || data === void 0 ? void 0 : data.background) === null || _f === void 0 ? void 0 : _f['island-inner']) === null || _g === void 0 ? void 0 : _g.value, defaultValue: 'rgb(245, 245, 255)' },
        _a['background-island-shadow'] = {
            value: getShadowColor((_j = (_h = data === null || data === void 0 ? void 0 : data.background) === null || _h === void 0 ? void 0 : _h['island-shadow']) === null || _j === void 0 ? void 0 : _j.value),
            defaultValue: '0 0.3125rem 1.25rem #0000000d',
        },
        _a['background-accent'] = { value: (_l = (_k = data === null || data === void 0 ? void 0 : data.background) === null || _k === void 0 ? void 0 : _k.accent) === null || _l === void 0 ? void 0 : _l.value, defaultValue: 'rgb(87, 89, 206)' },
        _a['background-border'] = { value: (_o = (_m = data === null || data === void 0 ? void 0 : data.background) === null || _m === void 0 ? void 0 : _m.border) === null || _o === void 0 ? void 0 : _o.value, defaultValue: 'rgb(224, 225, 229)' },
        _a['background-modal'] = { value: (_q = (_p = data === null || data === void 0 ? void 0 : data.background) === null || _p === void 0 ? void 0 : _p.modal) === null || _q === void 0 ? void 0 : _q.value, defaultValue: 'rgba(17, 18, 59, 0.6)' },
        _a['text-primary'] = { value: (_s = (_r = data === null || data === void 0 ? void 0 : data.text) === null || _r === void 0 ? void 0 : _r.primary) === null || _s === void 0 ? void 0 : _s.value, defaultValue: 'rgb(17, 18, 59)' },
        _a['text-primary-inverse'] = { value: (_u = (_t = data === null || data === void 0 ? void 0 : data.text) === null || _t === void 0 ? void 0 : _t['primary-inverse']) === null || _u === void 0 ? void 0 : _u.value, defaultValue: 'rgb(255, 255, 255)' },
        _a['text-secondary'] = { value: (_w = (_v = data === null || data === void 0 ? void 0 : data.text) === null || _v === void 0 ? void 0 : _v.secondary) === null || _w === void 0 ? void 0 : _w.value, defaultValue: 'rgb(112, 113, 137)' },
        _a['text-secondary-inverse'] = { value: (_y = (_x = data === null || data === void 0 ? void 0 : data.text) === null || _x === void 0 ? void 0 : _x['secondary-inverse']) === null || _y === void 0 ? void 0 : _y.value, defaultValue: 'rgb(236, 236, 239)' },
        _a['text-teritary'] = { value: (_0 = (_z = data === null || data === void 0 ? void 0 : data.text) === null || _z === void 0 ? void 0 : _z.teritary) === null || _0 === void 0 ? void 0 : _0.value, defaultValue: 'rgb(184, 184, 196)' },
        _a['text-teritary-inverse'] = { value: (_2 = (_1 = data === null || data === void 0 ? void 0 : data.text) === null || _1 === void 0 ? void 0 : _1['teritary-inverse']) === null || _2 === void 0 ? void 0 : _2.value, defaultValue: 'rgb(184, 184, 196)' },
        _a['text-link'] = { value: (_4 = (_3 = data === null || data === void 0 ? void 0 : data.text) === null || _3 === void 0 ? void 0 : _3.link) === null || _4 === void 0 ? void 0 : _4.value, defaultValue: 'rgb(87, 89, 206)' },
        _a['icons-primary'] = { value: (_6 = (_5 = data === null || data === void 0 ? void 0 : data.icons) === null || _5 === void 0 ? void 0 : _5.primary) === null || _6 === void 0 ? void 0 : _6.value, defaultValue: 'rgb(87, 89, 206)' },
        _a['icons-primary-inverse'] = { value: (_8 = (_7 = data === null || data === void 0 ? void 0 : data.icons) === null || _7 === void 0 ? void 0 : _7['primary-inverse']) === null || _8 === void 0 ? void 0 : _8.value, defaultValue: 'rgb(255, 255, 255)' },
        _a['buttons-primary-fill-border-default'] = {
            value: (_11 = (_10 = (_9 = data === null || data === void 0 ? void 0 : data.buttons) === null || _9 === void 0 ? void 0 : _9.primary) === null || _10 === void 0 ? void 0 : _10['fill-border-default']) === null || _11 === void 0 ? void 0 : _11.value,
            defaultValue: 'rgb(87, 89, 206)',
        },
        _a['buttons-primary-fill-border-hover'] = {
            value: (_14 = (_13 = (_12 = data === null || data === void 0 ? void 0 : data.buttons) === null || _12 === void 0 ? void 0 : _12.primary) === null || _13 === void 0 ? void 0 : _13['fill-border-hover']) === null || _14 === void 0 ? void 0 : _14.value,
            defaultValue: 'rgb(107, 110, 243)',
        },
        _a['buttons-primary-fill-border-active'] = {
            value: (_17 = (_16 = (_15 = data === null || data === void 0 ? void 0 : data.buttons) === null || _15 === void 0 ? void 0 : _15.primary) === null || _16 === void 0 ? void 0 : _16['fill-border-active']) === null || _17 === void 0 ? void 0 : _17.value,
            defaultValue: 'rgb(69, 71, 168)',
        },
        _a['buttons-primary-fill-border-disabled'] = {
            value: (_20 = (_19 = (_18 = data === null || data === void 0 ? void 0 : data.buttons) === null || _18 === void 0 ? void 0 : _18.primary) === null || _19 === void 0 ? void 0 : _19['fill-border-disabled']) === null || _20 === void 0 ? void 0 : _20.value,
            defaultValue: 'rgb(183, 184, 225)',
        },
        _a['buttons-primary-text'] = { value: (_23 = (_22 = (_21 = data === null || data === void 0 ? void 0 : data.buttons) === null || _21 === void 0 ? void 0 : _21.primary) === null || _22 === void 0 ? void 0 : _22['text']) === null || _23 === void 0 ? void 0 : _23.value, defaultValue: 'rgb(255, 255, 255)' },
        _a['buttons-inverse-fill'] = { value: (_26 = (_25 = (_24 = data === null || data === void 0 ? void 0 : data.buttons) === null || _24 === void 0 ? void 0 : _24.inverse) === null || _25 === void 0 ? void 0 : _25.fill) === null || _26 === void 0 ? void 0 : _26.value, defaultValue: 'rgb(255, 255, 255)' },
        _a['buttons-inverse-text-default'] = {
            value: (_29 = (_28 = (_27 = data === null || data === void 0 ? void 0 : data.buttons) === null || _27 === void 0 ? void 0 : _27.inverse) === null || _28 === void 0 ? void 0 : _28['text-default']) === null || _29 === void 0 ? void 0 : _29.value,
            defaultValue: 'rgb(87, 89, 206)',
        },
        _a['buttons-inverse-text-hover'] = {
            value: (_32 = (_31 = (_30 = data === null || data === void 0 ? void 0 : data.buttons) === null || _30 === void 0 ? void 0 : _30.inverse) === null || _31 === void 0 ? void 0 : _31['text-hover']) === null || _32 === void 0 ? void 0 : _32.value,
            defaultValue: 'rgb(69, 71, 168)',
        },
        _a['buttons-inverse-shadow-hover'] = {
            value: getShadowColor((_35 = (_34 = (_33 = data === null || data === void 0 ? void 0 : data.buttons) === null || _33 === void 0 ? void 0 : _33.inverse) === null || _34 === void 0 ? void 0 : _34['shadow-hover']) === null || _35 === void 0 ? void 0 : _35.value),
            defaultValue: '0 0 1.5rem rgb(59, 60, 141)',
        },
        _a['buttons-inverse-text-active'] = {
            value: (_38 = (_37 = (_36 = data === null || data === void 0 ? void 0 : data.buttons) === null || _36 === void 0 ? void 0 : _36.inverse) === null || _37 === void 0 ? void 0 : _37['text-active']) === null || _38 === void 0 ? void 0 : _38.value,
            defaultValue: 'rgb(69, 71, 168)',
        },
        _a['buttons-inverse-shadow-active'] = {
            value: getShadowColor((_41 = (_40 = (_39 = data === null || data === void 0 ? void 0 : data.buttons) === null || _39 === void 0 ? void 0 : _39.inverse) === null || _40 === void 0 ? void 0 : _40['shadow-active']) === null || _41 === void 0 ? void 0 : _41.value),
            defaultValue: '0 0 1.5rem rgb(169, 169, 219)',
        },
        _a['buttons-inverse-text-disabled'] = {
            value: (_44 = (_43 = (_42 = data === null || data === void 0 ? void 0 : data.buttons) === null || _42 === void 0 ? void 0 : _42.inverse) === null || _43 === void 0 ? void 0 : _43['text-disabled']) === null || _44 === void 0 ? void 0 : _44.value,
            defaultValue: 'rgb(183, 184, 225)',
        },
        _a['buttons-outline-fill'] = { value: (_47 = (_46 = (_45 = data === null || data === void 0 ? void 0 : data.buttons) === null || _45 === void 0 ? void 0 : _45.outline) === null || _46 === void 0 ? void 0 : _46.fill) === null || _47 === void 0 ? void 0 : _47.value, defaultValue: 'rgb(255, 255, 255)' },
        _a['buttons-outline-border-text-default'] = {
            value: (_50 = (_49 = (_48 = data === null || data === void 0 ? void 0 : data.buttons) === null || _48 === void 0 ? void 0 : _48.outline) === null || _49 === void 0 ? void 0 : _49['border-text-default']) === null || _50 === void 0 ? void 0 : _50.value,
            defaultValue: 'rgb(87, 89, 206)',
        },
        _a['buttons-outline-border-text-hover'] = {
            value: (_53 = (_52 = (_51 = data === null || data === void 0 ? void 0 : data.buttons) === null || _51 === void 0 ? void 0 : _51.outline) === null || _52 === void 0 ? void 0 : _52['border-text-hover']) === null || _53 === void 0 ? void 0 : _53.value,
            defaultValue: 'rgb(107, 110, 243)',
        },
        _a['buttons-outline-border-text-active'] = {
            value: (_56 = (_55 = (_54 = data === null || data === void 0 ? void 0 : data.buttons) === null || _54 === void 0 ? void 0 : _54.outline) === null || _55 === void 0 ? void 0 : _55['border-text-active']) === null || _56 === void 0 ? void 0 : _56.value,
            defaultValue: 'rgb(69, 71, 168)',
        },
        _a['buttons-outline-border-text-disabled'] = {
            value: (_59 = (_58 = (_57 = data === null || data === void 0 ? void 0 : data.buttons) === null || _57 === void 0 ? void 0 : _57.outline) === null || _58 === void 0 ? void 0 : _58['border-text-disabled']) === null || _59 === void 0 ? void 0 : _59.value,
            defaultValue: 'rgb(183, 184, 225)',
        },
        _a);
};
var getRgbValue = function (rgbColor) { return rgbColor.replace('rgb(', '').replace(')', '').split(','); };
var getCurrentRgbPartFn = function (alfa) { return function (value) {
    var currentValue = parseInt(value) + alfa;
    currentValue = Math.round(currentValue < 255 ? (currentValue < 0 ? 0 : currentValue) : 255);
    return currentValue.toString(16).length == 1 ? '0' + currentValue.toString(16) : currentValue.toString(16);
}; };
export var shadeRgbColor = function (rgbColor, percent) {
    if (!rgbColor.includes('rgb('))
        return rgbColor;
    var onePercentValue = 255 / 100;
    var _a = getRgbValue(rgbColor), r = _a[0], g = _a[1], b = _a[2];
    var convertRgbValuePartToHexPart = getCurrentRgbPartFn(percent * onePercentValue);
    return "#".concat(convertRgbValuePartToHexPart(r)).concat(convertRgbValuePartToHexPart(g)).concat(convertRgbValuePartToHexPart(b));
};
export var prepareRgbToRgba = function (color, alfa) {
    return !color.includes('rgb(') ? color : "rgba(".concat(getRgbValue(color).join('.'), ", ").concat(alfa, ")");
};
export var getCurrentColorStyleFn = function (data) { return function (key) { var _a; return (_a = data[key].value) !== null && _a !== void 0 ? _a : data[key].defaultValue; }; };
