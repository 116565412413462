import { createSlice } from '@reduxjs/toolkit';
import { CREATE_POST_SERVICES_REDUCER_NAMESPACES } from './constants';
export var initialState = {
    isDeleteSurveyPostModalOpen: false,
};
var createPostServiceState = createSlice({
    name: CREATE_POST_SERVICES_REDUCER_NAMESPACES.createPostForm,
    initialState: initialState,
    reducers: {
        closeDeleteSurveyPostModal: function (state) {
            state.isDeleteSurveyPostModalOpen = false;
        },
        openDeleteSurveyPostModal: function (state) {
            state.isDeleteSurveyPostModalOpen = true;
        },
        resetState: function () { return initialState; },
    },
});
export var actions = createPostServiceState.actions;
export default createPostServiceState.reducer;
