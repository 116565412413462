var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from 'ui-kit/box';
import { Icon } from 'ui-kit/icon';
import { useEnvironment } from 'ui-kit/environment';
import styles from './popup-setting-list-item.module.scss';
export var PopupSettingListItem = function (_a) {
    var icon = _a.icon, text = _a.text, component = _a.component, prefix = _a.prefix, dataTestid = _a.dataTestid, onClick = _a.onClick;
    var environmentName = useEnvironment();
    return (_jsxs(Box, __assign({ className: styles.popupSettingsListItem, display: "flex", alignItems: "center", wrap: "nowrap", component: component !== null && component !== void 0 ? component : 'li', dataTestid: dataTestid || "".concat(environmentName, "_settingItem"), onClick: onClick }, { children: [prefix, icon && _jsx(Icon, { className: styles.icon, component: icon, color: "text" }), _jsx("span", __assign({ className: styles.text }, { children: text }))] })));
};
