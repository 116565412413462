var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box } from 'ui-kit/box';
import { EnvironmentName } from 'ui-kit/environment';
import { Ellipsis } from 'common/layouts/icons';
import { MenuButton, ToggleOpenMenuButton } from './modules';
import { getUserRoutes } from 'common/constants/routes';
import styles from './mobile-footer.module.scss';
export var MobileFooter = function () {
    var t = useTranslation().t;
    var menuItems = getUserRoutes()
        .filter(function (item) { return item.isShownInFooter; })
        .map(function (item) { return _jsx(MenuButton, { icon: item.icon, text: item.title, link: item.path }, item.path); });
    return (_jsx(EnvironmentName, __assign({ name: "mobile-footer" }, { children: _jsxs(Box, __assign({ className: styles.footer, display: "flex", wrap: "nowrap", alignItems: "center", justify: "space-around", component: "footer" }, { children: [menuItems, _jsx(ToggleOpenMenuButton, { children: function (_a) {
                        var isActive = _a.isActive, onClick = _a.onClick;
                        return (_jsx(MenuButton, { icon: Ellipsis, text: t('common.menu.showMore'), isActive: isActive, onClick: onClick }));
                    } })] })) })));
};
