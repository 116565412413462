export var StatisticSurveyStatusType;
(function (StatisticSurveyStatusType) {
    StatisticSurveyStatusType["Archived"] = "Archived";
    StatisticSurveyStatusType["Planned"] = "Planned";
    StatisticSurveyStatusType["InProgress"] = "InProgress";
    StatisticSurveyStatusType["Finished"] = "Finished";
})(StatisticSurveyStatusType || (StatisticSurveyStatusType = {}));
export var StatisticSurveyType;
(function (StatisticSurveyType) {
    StatisticSurveyType["Custom"] = "Custom";
    StatisticSurveyType["EmployeeAssessment"] = "EmployeeAssessment";
    StatisticSurveyType["EmployeeNetPromoterScore"] = "EmployeeNetPromoterScore";
    StatisticSurveyType["NewsFeedSurvey"] = "NewsFeedSurvey";
})(StatisticSurveyType || (StatisticSurveyType = {}));
