import { useSelector } from 'react-redux';
import { profileServiceAvailablePagesSelector } from '../services/profile-service/selectors';
import { useBreakpoints } from './use-breakpoints';
import { checkEqual } from '../helpers/checkEqual';
export var useAvailablePageData = function () {
    var availablePagesDto = useSelector(profileServiceAvailablePagesSelector, checkEqual);
    var screenData = useBreakpoints();
    return {
        pageData: availablePagesDto,
        screenData: screenData,
    };
};
