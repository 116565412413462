var _a;
import { combineReducers } from 'redux';
import surveyQuestionsServiceStateReducer from './survey-questions-service/reducer';
import surveyTemplateQuestionsFilterServiceStateReducer from './survey-template-questions-filter/reducer';
import surveyPostFormServicesRootReducer from '../modules/create-or-edit-survey-post/modules/survey-post-form/services/root-reducer';
import questionCategoriesReducer from './question-categories-service/reducer';
import finishModalsServiceReducer from './finish-modals-service/reducer';
import { SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES } from './constants';
export var surveysCommonServicesRootReducer = combineReducers((_a = {},
    _a[SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.surveyQuestions] = surveyQuestionsServiceStateReducer,
    _a[SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.surveyTemplateQuestionsFilter] = surveyTemplateQuestionsFilterServiceStateReducer,
    _a[SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.surveyPostForm] = surveyPostFormServicesRootReducer,
    _a[SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.questionCategories] = questionCategoriesReducer,
    _a[SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.finishModals] = finishModalsServiceReducer,
    _a));
