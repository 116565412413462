import { createSlice } from '@reduxjs/toolkit';
import { SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES } from 'pages/manager-pages/modules/surveys-common/services/constants';
export var initialState = {
    searchString: '',
    questionType: undefined,
    categoryId: undefined,
};
var surveyTemplateQuestionsFilterServiceState = createSlice({
    name: SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.surveyTemplateQuestionsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        choiceSortingVariant: function (state, action) {
            state.questionType = action.payload;
        },
        selectCategory: function (state, action) {
            state.categoryId = action.payload;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = surveyTemplateQuestionsFilterServiceState.actions;
export default surveyTemplateQuestionsFilterServiceState.reducer;
