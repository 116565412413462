var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useModalActions } from 'common/services/modal-service/hooks';
import { modalServiceVisibleModalsSelector } from 'common/services/modal-service/selectors';
import { checkEqual } from 'common/helpers/checkEqual';
export var ModalsContainer = function () {
    var removeModal = useModalActions().removeModal;
    var modals = useSelector(modalServiceVisibleModalsSelector, checkEqual);
    if (!(modals === null || modals === void 0 ? void 0 : modals.length)) {
        return null;
    }
    return (_jsx("div", { children: modals.map(function (_a, index) {
            var Component = _a.Component, onSuccess = _a.onSuccess, id = _a.id, data = _a.modalProps;
            return (_jsx(Component, __assign({ isOpen: index === modals.length - 1, closeModal: function () { return removeModal(id); }, onSuccess: onSuccess }, data), id));
        }) }));
};
