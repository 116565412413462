var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FeedbackTriggerType, SupportRequestThemeType } from 'common/types/server-api-dtos';
import { createSupportRequestManagerApi, createSupportRequestUserApi } from './service/api';
import { useFeedbackActions } from 'pages/services/feedback-service/hooks';
import { EE, EventsBus } from 'common/utils/events-bus';
import { useToastActions } from 'common/services/toast-service/hooks';
import { profileServiceUserIdSelector } from 'common/services/profile-service/selectors';
import { supportRequestTypesServiceOptionsDataSelector, supportRequestTypesServiceOptionsSelector, supportRequestTypesServiceRequestIdByThemeSelector, } from 'common/services/support-request-types-service/selectors';
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import { Modal } from 'ui-kit/modal';
import { Button } from 'ui-kit/button';
import { SelectField } from 'ui-kit/fields/select-field';
import { TextAreaField } from 'ui-kit/fields/text-area-field';
import { DropzoneField } from 'ui-kit/fields/dropzone-field';
import { FormInner } from 'ui-kit/form-inner';
import { FormErrorsToast } from 'common/components/form-errors-toast';
import { FormButtonSetWrapper } from 'common/components/form-button-set-wrapper';
import { ErrorObserver } from './modules';
import { getCreateSupportRequestFormSchema } from './create-support-reuest-form.schema';
import { checkEqual } from 'common/helpers/checkEqual';
import { makeValidateSync } from 'common/utils/form/make-validate-sync';
import { DEV_REQUEST_ERR_KEY } from './constants';
import styles from './create-support-reuest-form.module.scss';
var FORM_NAME = 'createSupportRequestForm';
var CREATE_SUPPORT_REQUEST_BUTTON_TESTID = 'creataSupportRequestButton';
var INITIAL_FORM_STATE = {
    isOpen: false,
    selectedTheme: undefined,
    creatorOptionsSelector: undefined,
};
var ACCEPTED_FILES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
var ACCEPTED_FILES_TYPE = ACCEPTED_FILES.join(',');
var INITIAL_VALUES = {
    typeId: '',
    creatorId: '',
    text: '',
    formFileCollection: [],
};
export var CreateSupportRequestFormRoot = function (_a) {
    var isOpen = _a.isOpen, creatorOptionsSelector = _a.creatorOptionsSelector, selectedTheme = _a.selectedTheme, onClose = _a.onClose;
    var t = useTranslation().t;
    var addSuccessToast = useToastActions().addSuccessToast;
    var fetchNeedFeedbackAction = useFeedbackActions().fetchNeedFeedbackAction;
    var creatorId = useSelector(profileServiceUserIdSelector);
    var optionsData = useSelector(supportRequestTypesServiceOptionsDataSelector, checkEqual);
    var typeId = useSelector(supportRequestTypesServiceRequestIdByThemeSelector(selectedTheme));
    var validate = useMemo(function () { return makeValidateSync(getCreateSupportRequestFormSchema(t)); }, [t]);
    var isManagerForm = !!creatorOptionsSelector;
    var handleSubmitForm = useCallback(function (values) {
        return (isManagerForm ? createSupportRequestManagerApi : createSupportRequestUserApi)(values)
            .then(function () {
            EE.emit(EventsBus.fetchUserRequests);
            addSuccessToast({ title: t('common.createSupportRequestForm.successMessage') });
            onClose();
            fetchNeedFeedbackAction(FeedbackTriggerType.SupportRequestCreated);
        })
            .catch(function () {
            var _a;
            var _b;
            var requestType = (_b = optionsData.find(function (el) { return el.id === values.typeId; })) === null || _b === void 0 ? void 0 : _b.themeType;
            if (requestType === SupportRequestThemeType.DevelopersQuestion) {
                return _a = {},
                    _a[DEV_REQUEST_ERR_KEY] = true,
                    _a;
            }
        });
    }, [addSuccessToast, onClose, fetchNeedFeedbackAction, t, optionsData, isManagerForm]);
    return (_jsx(Modal, __assign({ className: styles.modalWrapper, isOpen: isOpen && !!creatorId, title: t('common.createSupportRequestForm.title'), onClose: onClose }, { children: _jsx(Form, { initialValues: __assign(__assign({}, INITIAL_VALUES), { creatorId: creatorId, typeId: typeId }), subscription: {}, validate: validate, onSubmit: handleSubmitForm, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (_jsxs(FormInner, __assign({ name: FORM_NAME, onSubmit: handleSubmit }, { children: [_jsx(Typography, { children: t('common.createSupportRequestForm.subTitle') }), _jsx(ErrorObserver, { mt: 8 }), _jsxs(Box, __assign({ pt: 16, gap: 12, display: "flex", direction: "column" }, { children: [_jsx(SelectField, { isRequired: true, name: "typeId", label: t('common.createSupportRequestForm.typeIdLabel'), placeholder: t('common.createSupportRequestForm.typeIdPlaceholder'), options: supportRequestTypesServiceOptionsSelector }), _jsx(SelectField, { isRequired: true, name: "creatorId", label: t('common.createSupportRequestForm.creatorIdLabel'), placeholder: t('common.createSupportRequestForm.creatorIdPlaceholder'), options: creatorOptionsSelector, hidden: !isManagerForm }), _jsx(TextAreaField, { isRequired: true, name: "text", label: t('common.createSupportRequestForm.textLabel'), placeholder: t('common.createSupportRequestForm.textPlaceholder'), maxLength: 1000 }), _jsx(DropzoneField, { name: "formFileCollection", title: t('common.createSupportRequestForm.formFileCollectionLabel'), maxFiles: 10, accept: ACCEPTED_FILES_TYPE })] })), _jsx(Modal.Footer, { children: _jsx(FormButtonSetWrapper, { children: function (_a) {
                                    var submitLoading = _a.submitLoading, submitDisabled = _a.submitDisabled;
                                    return (_jsx(Button, __assign({ type: "submit", loading: submitLoading, disabled: submitDisabled, dataTestid: CREATE_SUPPORT_REQUEST_BUTTON_TESTID }, { children: t('common.createSupportRequestForm.submitButtonText') })));
                                } }) }), _jsx(FormErrorsToast, {})] })));
            } }) })));
};
export var CreateSupportRequestForm = function () {
    var _a = useState(INITIAL_FORM_STATE), state = _a[0], setState = _a[1];
    useEffect(function () {
        var openCreateRequestFormCb = function (params) {
            setState(params);
        };
        EE.on(EventsBus.openCreateRequestForm, openCreateRequestFormCb);
        return function () {
            EE.off(EventsBus.openCreateRequestForm, openCreateRequestFormCb);
        };
    }, []);
    var closeFormHandler = useCallback(function () {
        setState(INITIAL_FORM_STATE);
    }, []);
    return _jsx(CreateSupportRequestFormRoot, __assign({}, state, { onClose: closeFormHandler }));
};
