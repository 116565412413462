import { createSlice } from '@reduxjs/toolkit';
import { baseUserDetailPostReducers, baseUserDetailPostState, } from '../../../services/detail-post-service/user/reducer';
import { DETAIL_SURVEY_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
var userDetailSurveyPostServiceState = createSlice({
    name: DETAIL_SURVEY_POST_ROOT_SERVICES_REDUCER_NAMESPACES.userDetailSurveyPost,
    initialState: baseUserDetailPostState,
    reducers: baseUserDetailPostReducers,
});
export var actions = userDetailSurveyPostServiceState.actions;
export default userDetailSurveyPostServiceState.reducer;
