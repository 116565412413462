import { createSlice } from '@reduxjs/toolkit';
import { SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    isOpenCancelModal: false,
    isOpenFinishModal: false,
    successCb: undefined,
};
var finishModalsServiceState = createSlice({
    name: SURVEYS_COMMON_SERVICES_REDUCER_NAMESPACES.finishModals,
    initialState: initialState,
    reducers: {
        openCancelModal: function (state, action) {
            state.successCb = action.payload;
            state.isOpenCancelModal = true;
        },
        openFinishModal: function (state, action) {
            state.successCb = action.payload;
            state.isOpenFinishModal = true;
        },
        closeCancelModal: function (state) {
            state.successCb = undefined;
            state.isOpenCancelModal = false;
        },
        closeFinishModal: function (state) {
            state.successCb = undefined;
            state.isOpenFinishModal = false;
        },
        reset: function () { return initialState; },
    },
});
export var actions = finishModalsServiceState.actions;
export default finishModalsServiceState.reducer;
