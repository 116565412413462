var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import cs from 'classnames';
import { Portal } from 'ui-kit/portal';
import { useEnvironment } from '../environment';
import styles from './popup.module.scss';
var setPopupPosition = function (_a) {
    var buttonElement = _a.buttonElement, popupElement = _a.popupElement, _b = _a.placement, placement = _b === void 0 ? 'bottom-start-right' : _b, _c = _a.gup, gup = _c === void 0 ? 12 : _c;
    var _d = buttonElement.getBoundingClientRect(), left = _d.left, top = _d.top, buttonWidth = _d.width, buttonHeight = _d.height;
    var _e = popupElement.getBoundingClientRect(), popupWidth = _e.width, popupHeight = _e.height;
    var centerOfButtonHeight = buttonHeight / 2 - popupHeight / 2;
    var centerOfButtonWidth = buttonWidth / 2 - popupWidth / 2;
    var topOfButton = top - popupHeight - gup;
    var bottomOfButton = top + buttonHeight + gup;
    switch (placement) {
        case 'top-center':
            popupElement.style.top = topOfButton + 'px';
            popupElement.style.left = left + centerOfButtonWidth + 'px';
            break;
        case 'top-start-right':
            popupElement.style.top = topOfButton + 'px';
            popupElement.style.left = left + buttonWidth - popupWidth + 'px';
            break;
        case 'top-start-left':
            popupElement.style.top = topOfButton + 'px';
            popupElement.style.left = left + 'px';
            break;
        case 'right-center':
            popupElement.style.top = top + centerOfButtonHeight + 'px';
            popupElement.style.left = left + buttonWidth + gup + 'px';
            break;
        case 'bottom-center':
            popupElement.style.top = bottomOfButton + 'px';
            popupElement.style.left = left + centerOfButtonWidth + 'px';
            break;
        case 'bottom-start-left':
            popupElement.style.top = bottomOfButton + 'px';
            popupElement.style.left = left + 'px';
            break;
        case 'left-center':
            popupElement.style.top = top + centerOfButtonHeight + 'px';
            popupElement.style.left = left - popupWidth - gup + 'px';
            break;
        default:
            popupElement.style.top = bottomOfButton + 'px';
            popupElement.style.left = left + buttonWidth - popupWidth + 'px';
    }
};
export var Popup = function (_a) {
    var button = _a.button, placement = _a.placement, gup = _a.gup, disabled = _a.disabled, closeOnScroll = _a.closeOnScroll, className = _a.className, dataTestid = _a.dataTestid, children = _a.children, renderButton = _a.renderButton, onObservePopup = _a.onObservePopup;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var environmentName = useEnvironment();
    var buttonElementRef = useRef(null);
    var popupElementRef = useRef(null);
    var editPopupPostion = useCallback(function () {
        var _a;
        (_a = popupElementRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        if (buttonElementRef.current && popupElementRef.current) {
            setPopupPosition({
                buttonElement: buttonElementRef.current,
                popupElement: popupElementRef.current,
                placement: placement,
                gup: gup,
            });
        }
    }, [placement, gup]);
    useLayoutEffect(function () {
        var scrollCb = (closeOnScroll !== null && closeOnScroll !== void 0 ? closeOnScroll : true)
            ? function () {
                setShow(false);
            }
            : editPopupPostion;
        if (show) {
            editPopupPostion();
            window.addEventListener('scroll', scrollCb);
        }
        else {
            return function () {
                popupElementRef.current = null;
                window.removeEventListener('scroll', scrollCb);
            };
        }
    }, [editPopupPostion, show, closeOnScroll]);
    var toggleOpenPopupHandler = function (e) {
        e.stopPropagation();
        if (!buttonElementRef.current) {
            buttonElementRef.current = e.currentTarget;
        }
        onObservePopup === null || onObservePopup === void 0 ? void 0 : onObservePopup(!show);
        setShow(!show);
    };
    var closePopupHandler = useCallback(function () {
        setShow(false);
    }, []);
    var popupClickHandler = useCallback(function (e) {
        e.stopPropagation();
    }, []);
    return (_jsx(_Fragment, { children: disabled ? (button) : (_jsxs(_Fragment, { children: [button
                    ? React.cloneElement(button, {
                        style: { cursor: 'pointer' },
                        onClick: toggleOpenPopupHandler,
                    })
                    : renderButton(show, toggleOpenPopupHandler), show && (_jsx(Portal, { children: _jsx("div", __assign({ className: cs(styles.popup, className), ref: popupElementRef, "data-testid": dataTestid || "".concat(environmentName, "_popup"), onClick: popupClickHandler, onMouseLeave: closePopupHandler }, { children: children })) }))] })) }));
};
