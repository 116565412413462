var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { AWARDS_PAGE_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialModalFilterValues = {
    awardType: null,
    showOnlyAwardedAchievements: false,
    showOnlyAwardedToMe: undefined,
    status: null,
};
export var initialState = {
    searchString: '',
    isOpenModalFilter: false,
    isOpenEmployeeAwardForm: false,
    modalFilterValues: initialModalFilterValues,
};
var awardsFilterServiceState = createSlice({
    name: AWARDS_PAGE_SERVICES_REDUCER_NAMESPACES.awardsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        openFilterModal: function (state) {
            state.isOpenModalFilter = true;
        },
        closeFilterModal: function (state) {
            state.isOpenModalFilter = false;
        },
        setModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterValues: function (state) {
            state.modalFilterValues = initialModalFilterValues;
        },
        resetModalFilterField: function (state, action) {
            var _a;
            var key = action.payload;
            state.modalFilterValues = __assign(__assign({}, state.modalFilterValues), (_a = {}, _a[key] = ['awardType', 'status'].includes(key) ? null : false, _a));
        },
        openEmployeeAwardForm: function (state) {
            state.isOpenEmployeeAwardForm = true;
        },
        closeEmployeeAwardForm: function (state) {
            state.isOpenEmployeeAwardForm = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = awardsFilterServiceState.actions;
export default awardsFilterServiceState.reducer;
