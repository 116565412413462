import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { customizationsServiceGetFeatureKeysSelector } from 'common/services/customizations-service/selectors';
import { Forbidden } from 'pages/error-pages/forbidden';
import { useAvailablePageData } from 'common/hooks/use-check-available-page-data';
import { userService } from 'common/utils/user-service';
import { checkEqual } from 'common/helpers/checkEqual';
export var RolesRoute = function (_a) {
    var title = _a.title, owner = _a.owner, featureToggleKey = _a.featureToggleKey, checkAvailable = _a.checkAvailable, children = _a.children;
    var featureToggleKeys = useSelector(customizationsServiceGetFeatureKeysSelector, checkEqual);
    var availablePages = useAvailablePageData();
    var isUserHasAccess = !owner || userService.hasRole(owner);
    var isPageNotAvailable = (featureToggleKey && !featureToggleKeys.includes(featureToggleKey)) ||
        (checkAvailable && availablePages.pageData && !checkAvailable(availablePages));
    var isAccess = isUserHasAccess && !isPageNotAvailable;
    useEffect(function () {
        if (title)
            document.title = isAccess ? title : '403 Forbidden';
    }, [title, isAccess]);
    return isAccess ? _jsx(_Fragment, { children: children }) : _jsx(Forbidden, {});
};
