import { useLayoutEffect, useState } from 'react';
import { useSidebarActions } from '../../../../service/sidebar-service/hooks';
export var ResizeObserver = function () {
    var reset = useSidebarActions().reset;
    var _a = useState(window.matchMedia('screen and (max-width: 1425px)').matches), isToggled = _a[0], setIsToggled = _a[1];
    useLayoutEffect(function () {
        if (!isToggled) {
            reset();
        }
    }, [reset, isToggled]);
    useLayoutEffect(function () {
        var cb = function () {
            if (window.matchMedia('screen and (min-width: 1425px)').matches && isToggled) {
                setIsToggled(false);
            }
            else if (window.matchMedia('screen and (max-width: 1425px)').matches && !isToggled) {
                setIsToggled(true);
            }
        };
        window.addEventListener('resize', cb);
        return function () { return window.removeEventListener('resize', cb); };
    }, [reset, isToggled]);
    return null;
};
