import { createSlice } from '@reduxjs/toolkit';
import { baseManagerDetailPostInitialState, baseManagerDetailPostReducers, } from '../../../services/detail-post-service/manager/reducer';
import { DETAIL_SURVEY_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
var managerDetailSurveyPostServiceState = createSlice({
    name: DETAIL_SURVEY_POST_ROOT_SERVICES_REDUCER_NAMESPACES.managerDetailSurveyPost,
    initialState: baseManagerDetailPostInitialState,
    reducers: baseManagerDetailPostReducers,
});
export var actions = managerDetailSurveyPostServiceState.actions;
export default managerDetailSurveyPostServiceState.reducer;
