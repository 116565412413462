var _a;
import { combineReducers } from 'redux';
import employeesCountServiceReducer from './employees-count-service/reducer';
import surveyPostFormServiceServiceReducer from './survey-post-form-service/reducer';
import surveyPostServiceServiceReducer from './survey-post-service/reducer';
import { SURVEY_POST_FORM_SERVICES_REDUCER_NAMESPACES } from './constants';
var surveyPostFormServicesRootReducer = combineReducers((_a = {},
    _a[SURVEY_POST_FORM_SERVICES_REDUCER_NAMESPACES.employeesCount] = employeesCountServiceReducer,
    _a[SURVEY_POST_FORM_SERVICES_REDUCER_NAMESPACES.surveyPostService] = surveyPostServiceServiceReducer,
    _a[SURVEY_POST_FORM_SERVICES_REDUCER_NAMESPACES.surveyPostFormService] = surveyPostFormServiceServiceReducer,
    _a));
export default surveyPostFormServicesRootReducer;
