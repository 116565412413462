var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { TAKE_POSTS_COUNT } from '../../../constants';
import { USER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: [], isEndOfTheListReached: false, takePostsBefore: null });
var userPostsListServiceState = createSlice({
    name: USER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES.postsList,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, data = _a.data, prevTakePostsBefore = _a.prevTakePostsBefore;
            state.takePostsBefore = data.length ? data[data.length - 1].publishedOn : null;
            state.isEndOfTheListReached = data.length < TAKE_POSTS_COUNT;
            state.data = prevTakePostsBefore ? __spreadArray(__spreadArray([], state.data, true), data, true) : __spreadArray([], data, true);
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, editPostLikesCount: function (state, action) {
            var _a = action.payload, id = _a.id, reactionsCount = _a.reactionsCount, postIsLiked = _a.postIsLiked;
            state.data = state.data.map(function (item) {
                if (item.id !== id)
                    return item;
                return __assign(__assign({}, item), { reactionsCount: reactionsCount, currentUserReacted: postIsLiked });
            });
        }, editPostViewsCount: function (state, action) {
            var _a = action.payload, id = _a.id, viewsCount = _a.viewsCount;
            state.data = state.data.map(function (item) {
                if (item.id !== id)
                    return item;
                return __assign(__assign({}, item), { viewsCount: viewsCount });
            });
        }, editPostCommentsCount: function (state, action) {
            var _a = action.payload, postId = _a.postId, commentsCount = _a.commentsCount;
            state.data = state.data.map(function (item) {
                if (item.id !== postId)
                    return item;
                return __assign(__assign({}, item), { commentsCount: commentsCount });
            });
        }, editPost: function (state, action) {
            state.data = state.data.map(function (item) {
                if (item.id !== action.payload.id)
                    return item;
                return __assign(__assign({}, item), action.payload);
            });
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = userPostsListServiceState.actions;
export default userPostsListServiceState.reducer;
